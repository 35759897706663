import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from './DescriptionComponent'

const GalvanizedCable = () => {
  const classes = styles();
  return (
    <div>
      <Typography
        align="center"
        className={classes.pagetitle}
      >
        Vinyl Coated Cable
      </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <ModalImage
                small="https://www.e-rigging.com/thumbnail.asp?file=assets/images/p/73/vinyl-coated-galvanized-cable-reel.jpg&maxx=482&maxy=0"
                large="https://www.e-rigging.com/assets/images/p/73/vinyl-coated-galvanized-cable-reel.jpg"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt=""
            />
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <ModalImage
              small="https://www.e-rigging.com/thumbnail.asp?file=assets/images/p/73/7x19-Vinyl-Coated-Cable-graphic.jpg&maxx=482&maxy=0"
              large="https://www.e-rigging.com/assets/images/p/73/7x19-Vinyl-Coated-Cable-graphic.jpg"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
            />
          </Grid>
        </Grid>

        <DescriptionComponent 
          product="Vinyl Coated Cable"
          subtitle="PRO Strand Vinyl-coated Galvanized Cable is always hot-dip galvanized for maximum corrosion protection and service life."
          p1=" Our galvanized wire cable is a great general-purpose coated steel cable. Our high-quality vinyl coating provides a flexible but durable protective layer that helps prevent abrasion wear and corrosion damage to the strands.
          "
          p2="Our galvanized cable comes in flexible 7x19 construction and is coated with clear vinyl. PRO Strand coated cable complies with federal specification RR-W-410H.
          "
          p3="Our coated cable is available in diameters of 1/8” to 3/8”. Please note that the vinyl coating adds 1/16” of diameter to all of our coated cable. These cables are available on either 200’ or 500’ reels.
          "
          img="https://www.e-rigging.com/thumbnail.asp?file=assets/images/diagrams/product-specs/one-eighth-x-200-foot-coated-galvanized-cable-specification-diagram.jpg&maxx=482&maxy=0"
        />
       

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Vinyl Coated Cable
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#FFF100" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
              <TableCell className={classes.theader}>
                  Cable Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Diameter with Coating
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Breaking Strength
                </TableCell>
                <TableCell className={classes.theader}>
                  Working Load Limit 5:1 Design Factor
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
              
              <TableCell className={classes.tcell}>21105020</TableCell>
              <TableCell className={classes.tcell}>1/8"	</TableCell>
              <TableCell className={classes.tcell}>3/16"	</TableCell>
              <TableCell className={classes.tcell}>2000 lbs	</TableCell>
              <TableCell className={classes.tcell}>400 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>21115020</TableCell>
              <TableCell className={classes.tcell}>3/16"	</TableCell>
              <TableCell className={classes.tcell}>1/4"	</TableCell>
              <TableCell className={classes.tcell}>4,200 lbs	</TableCell>
              <TableCell className={classes.tcell}>840 lbs</TableCell>
              </TableRow>

              <TableRow>
              <TableCell className={classes.tcell}>21120020</TableCell>
               <TableCell className={classes.tcell}>1/4"	</TableCell>
               <TableCell className={classes.tcell}>5/16"	</TableCell>
               <TableCell className={classes.tcell}>7,000 lbs	</TableCell>
               <TableCell className={classes.tcell}>1,400 lbs</TableCell>
              </TableRow>

              <TableRow>
              <TableCell className={classes.tcell}>21125020</TableCell>
             
                <TableCell className={classes.tcell}>5/16"	</TableCell>
                <TableCell className={classes.tcell}>3/8"	</TableCell>
                <TableCell className={classes.tcell}>9,800 lbs	</TableCell>
                <TableCell className={classes.tcell}>1,960 lbs</TableCell>
              </TableRow>

              <TableRow>
                  <TableCell className={classes.tcell}>21130020</TableCell>
                  <TableCell className={classes.tcell}>3/8"	</TableCell>
                  <TableCell className={classes.tcell}>7/16"	</TableCell>
                  <TableCell className={classes.tcell}>14,400 lbs	</TableCell>
                  <TableCell className={classes.tcell}>2,880 lbs</TableCell>
              </TableRow>
             
            </TableBody>
          </Table>
        </TableContainer>

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
           Type 304 Stainless Steel Vinyl Coated Cable
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#FFF100" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
              <TableCell className={classes.theader}>
                  Cable Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Diameter with Coating
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Breaking Strength
                </TableCell>
                <TableCell className={classes.theader}>
                  Working Load Limit 5:1 Design Factor
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
              
              <TableCell className={classes.tcell}>22105020</TableCell>
              <TableCell className={classes.tcell}>1/8"	</TableCell>
              <TableCell className={classes.tcell}>3/16"	</TableCell>
              <TableCell className={classes.tcell}>1,760 lbs	</TableCell>
              <TableCell className={classes.tcell}>352 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>22115020</TableCell>
              <TableCell className={classes.tcell}>3/16"	</TableCell>
              <TableCell className={classes.tcell}>1/4"	</TableCell>
              <TableCell className={classes.tcell}>3,700 lbs	</TableCell>
              <TableCell className={classes.tcell}>740 lbs</TableCell>
              </TableRow>

              <TableRow>
              <TableCell className={classes.tcell}>22120020</TableCell>
               <TableCell className={classes.tcell}>1/4"	</TableCell>
               <TableCell className={classes.tcell}>5/16"	</TableCell>
               <TableCell className={classes.tcell}>6,400 lbs	</TableCell>
               <TableCell className={classes.tcell}>1,280 lbs</TableCell>
              </TableRow>

              <TableRow>
              <TableCell className={classes.tcell}>22125020</TableCell>
             
                <TableCell className={classes.tcell}>5/16"	</TableCell>
                <TableCell className={classes.tcell}>3/8"	</TableCell>
                <TableCell className={classes.tcell}>9,000 lbs	</TableCell>
                <TableCell className={classes.tcell}>1,800 lbs</TableCell>
              </TableRow>

             
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default GalvanizedCable;
